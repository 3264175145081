<script>
import json from "./data.json";
export default {
  data() {
    return {
      myJson: json,
    };
  },
};
</script>

<template>
  <header>
    <aside>
      <a class="map" href="https://511.gov.pe.ca/">MAP</a>
    </aside>
    <h1 class="center-text">Prince Edward Island Webcams</h1>
    <a class="footer" href="http://visitcanada.live">visitcanada.live</a>
  </header>
  <table>
    <figure v-for="location in myJson" :key="location.id">
      <td v-for="y in location.cameras" :key="y.cameras">
        <a v-bind:href="y.thumbnail">
          <img v-bind:src="y.thumbnail" width="450" />
        </a>
        <figcaption>{{ y.title }}</figcaption>
        <h3>Location: {{ y.name }}</h3>
        <h3>Tourist Region: {{ y.tourist_region }}</h3>
      </td>
    </figure>
  </table>
  <footer>
    <a class="footer" href="http://visitcanada.live">visitcanada.live</a>
    <aside>Copyright 2022</aside>
  </footer>
</template>


<style>
h3 {
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
}

template,
table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  /* display: flex; */
}

/* body {
  font-family: 
} */

figcaption {
  font-size: 30px;
}

figure {
  display: inline-block;
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
  color: black;
}

h1 {
  text-align: center;
  font-size: 60px;
}

h2 {
  text-transform: uppercase;
  display: inline;
}

header,
footer {
  align-items: center;
  text-align: center;
  background-color: #b3b9af;
  padding: 30px;
  border-radius: 25px;
}

header {
  background-color: #b3b9af;
  border-radius: 25px;
  padding: 30px;
  margin-bottom: 20px;
}

.footer {
  /* align-items: center; */
  align-self: right;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.center-text {
  text-align: center;
  align-items: center;
}

.map {
  align-self: right;
  text-align: right;
  font-size: 24px;
  font-weight: bold;
}

aside {
  text-align: right;
}

body {
  background-color: #daddd8;
}

@media only screen and (max-width: 1400px) {
  table {
    max-width: 1200px;
  }

  td:nth-child(3) {
    display: block;
    /* background-color: yellow; */
  }

  tr {
    float: left;
  }

  td {
    float: left;
    min-width: 100px;
  }
}

@media screen and (max-width: 500px) {
  table {
    display: block;
  }
  td {
    display: block;
  }
}
</style>
